export default [
    { codigo: 'ET', tipo: 'Encuesta telef�nica (horas)', cargo: 'Encuestador', color: '#AD1457', color2: '#F48FB1' },
    { codigo: 'ETN', tipo: 'Encuesta telef�nica (encuestas que no est�n en INTEGRA)', cargo: 'Encuestador', color: '#00695C', color2: '#80CBC4' },
    { codigo: 'CT', tipo: 'Citaci�n telef�nica', cargo: 'Encuestador', color: '#FF8F00', color2: '#FFE082' },
    { codigo: 'BRI', tipo: 'B�squeda de registros por internet', cargo: 'Encuestador', color: '#c62828', color2: '#ef9a9a' },
    { codigo: 'LS', tipo: 'L�neas de servicios', cargo: 'Encuestador', color: '#2E7D32', color2: '#A5D6A7' },
    { codigo: 'RV', tipo: 'Revisi�n digital', cargo: 'Encuestador', color: '#7D2E79', color2: '#D6A5D4' },
    { codigo: 'COR', tipo: 'Control de inicio de operaci�n y reporte de personal', cargo: 'Supervisor telef�nico', color: '#9E9D24', color2: '#E6EE9C' },
    { codigo: 'IPS', tipo: 'Ingreso del personal a las aplicaciones de supervisi�n', cargo: 'Supervisor telef�nico', color: '#4527A0', color2: '#B39DDB' },
    { codigo: 'RB', tipo: 'Revisi�n de la base ', cargo: 'Supervisor telef�nico', color: '#F9A825', color2: '#FFF59D' },
    { codigo: 'MSV', tipo: 'Monitoreo supervisi�n en vivo', cargo: 'Supervisor telef�nico', color: '#283593', color2: '#9FA8DA' },
    { codigo: 'CPG', tipo: 'Costos y productividad del grupo', cargo: 'Supervisor telef�nico', color: '#1565C0', color2: '#90CAF9' },
    { codigo: 'RET', tipo: 'Retroalimentacion', cargo: 'Supervisor telef�nico', color: '#EF6C00', color2: '#FFCC80' },
    { codigo: 'SPE', tipo: 'Soporte de preguntas a encuestadores', cargo: 'Supervisor telef�nico', color: '#0277BD', color2: '#81D4FA' },
    { codigo: 'RLA', tipo: 'Reporte de llamados de atenci�n', cargo: 'Supervisor telef�nico', color: '#D84315', color2: '#FFAB91' },
    { codigo: 'INC', tipo: 'Informes de campo', cargo: 'Supervisor telef�nico', color: '#00838F', color2: '#80DEEA' },
    { codigo: 'SOR', tipo: 'Solicitud de registros', cargo: 'Supervisor telef�nico', color: '#4E342E', color2: '#BCAAA4' },
    { codigo: 'RRC', tipo: 'Revisi�n registros de capacitaci�n', cargo: 'Supervisor telef�nico', color: '#006928', color2: '#80cb9f' },
    { codigo: 'CAP', tipo: 'Capacitaci�n', cargo: 'Supervisor telef�nico y Encuestador', color: '#558B2F', color2: '#C5E1A5' },
    { codigo: 'COM', tipo: 'Compensatorio', cargo: 'Supervisor telef�nico y Encuestador', color: '#6A1B9A', color2: '#CE93D8' },
    { codigo: 'NOV', tipo: 'Novedad', cargo: 'Supervisor telef�nico y Encuestador', color: '#ff0000', color2: '#f34141' },
    { codigo: 'CTS', tipo: 'Campo telef�nico en sitio (presencial)', cargo: 'Supervisor telef�nico y Encuestador', color: '#00FFFF', color2: '#41F3F3' },
    { codigo: 'BNS', tipo: 'Bonos Telef�nico', cargo: 'Supervisor telef�nico y Encuestador', color: '#F9A825', color2: '#FDD835' },
    { codigo: 'CE', tipo: 'Citaci�n Empresas', cargo: 'Supervisor telef�nico y Encuestador', color: '#9F25F9', color2: '#C781FA' },
    { codigo: 'ACT', tipo: 'Asistencia de campo telef�nico', cargo: 'Supervisor telef�nico y Encuestador', color: '#CDDB16', color2: '#F2F991' },
    { codigo: 'TL', tipo: 'Tiempo laborado', cargo: 'Supervisor telef�nico y Encuestador', color: '#CDDB16', color2: '#BFFF00' },
    { codigo: 'TRPT', tipo: 'Transporte telef�nico', cargo: 'Supervisor telef�nico y Encuestador', color: '#1690DB', color2: '#91A4F9' },
    { codigo: 'EC', tipo: 'Encuestas Casa', cargo: 'Supervisor telef�nico y Encuestador', color: '#1690DB', color2: '#91A4F9' },
];
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Collapse,
    Popover,
    Divider
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/calendar-font.css';
import '../../../styles/hide_popup.css';
import moment from 'moment';
import { format } from 'date-fns';
import EsLocale from 'date-fns/locale/es';

import actividades from '../../../data/actividades';

require('moment/locale/es');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(() => ({
    btn: {
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#a6a6a6'
        },
        '&:disabled:hover': {
            backgroundColor: '#e0e0e0',
            border: '1px solid #ccc',
            color: '#a6a6a6'
        }
    }
}));

function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function Calendario(props) {
    const theme = props.theme;
    const eventos = props.eventos;
    const encuestador = props.encuestador;
    const classes = useStyles();
    const [startDate, endDate] = props.dates;
    const [mes, setMes] = useState(startDate.getMonth() + 1);
    const [anio, setAnio] = useState(startDate.getFullYear())
    const [heightCalendar, setHeightCalendar] = useState(weekHeight((new Date()).getFullYear(), (new Date()).getMonth() + 1));
    const [encuestadorActual, setEncuestadorActual] = useState(null);
    const [fechaPopup, setFechaPopup] = useState(null);
    const [eventosPopup, setEventosPopup] = useState(null);
    const [openPopoverPopup, setOpenPopoverPopup] = useState(false);
    const [openPopoverEvento, setOpenPopoverEvento] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [eventoActual2, setEventoActual2] = useState(null);

    class CustomToolbar extends Toolbar {
        render() {
            return (
                <div className='rbc-toolbar'>
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => this.navigate('TODAY')}>Hoy</button>
                        <button type="button" className={classes.btn} disabled={startDate.getMonth() + 1 === mes} onClick={() => this.navigate('PREV')}>&lt;&lt;</button>
                        <button type="button" className={classes.btn} disabled={endDate.getMonth() + 1 === mes} onClick={() => this.navigate('NEXT')}>&gt;&gt;</button>
                    </span>
                    <span className="rbc-toolbar-label">{this.props.label}</span>
                </div>
            );
        }

        navigate = action => {
            this.props.onNavigate(action)
        }
    }

    function weekHeight(year, month_number) {
        var firstOfMonth = new Date(year, month_number - 1, 1);
        var day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) { day-- }
        var diff = 7 - day;
        var lastOfMonth = new Date(year, month_number, 0);
        var lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        var result = Math.ceil((lastDate - diff) / 7);
        return result + 1 === 4 ? 581.5 : result + 1 === 5 ? 710 : 838.48;
    };

    const getColor = (e) => {
        const colorHex = !e.title.includes('encuesta') ? actividades.filter(a => a.codigo === e.actividad)[0].color : '#424242'
        const colorRgb = hexToRgb(colorHex);
        const luminanceColor = luminance(colorRgb.r, colorRgb.g, colorRgb.b);
        const luminanceColorW = luminance(255, 255, 255);
        const luminanceColorB = luminance(0, 0, 0);
        const ratioW = luminanceColor > luminanceColorW ? ((luminanceColorW + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorW + 0.05));
        const ratioB = luminanceColor > luminanceColorB ? ((luminanceColorB + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorB + 0.05));
        return [colorHex, ratioW < ratioB ? 'white' : 'black']
    }

    const closePopoverEvento = () => {
        setOpenPopoverEvento(false);
        setTimeout(() => {
            setEventoActual2(null);
            setEncuestadorActual(null)
        }, 150);
    }

    return (
        <React.Fragment>
            <Calendar
                components={{ toolbar: CustomToolbar }}
                popup
                defaultDate={startDate}
                style={{ height: heightCalendar }}
                localizer={localizer}
                min={startDate}
                events={eventos.filter(e => parseInt(e.start.split('-')[1]) === mes && parseInt(e.start.split('-')[0]) === anio)}
                startAccessor='start'
                endAccessor='end'
                view='month'
                views={['month']}
                messages={{
                    showMore: (target) => <Typography variant='subtitle2' onClick={(e) => setAnchorEl3(e.currentTarget)}> ...{target} m�s</Typography>,
                    next: '>>',
                    previous: '<<',
                    today: 'Hoy'
                }}
                onShowMore={(e, d) => { setEventosPopup(e); setFechaPopup(d); setOpenPopoverPopup(true); }}
                onRangeChange={({ start }) => {
                    var date = new Date(start);
                    date.setDate(date.getDate() + 8);
                    setMes((new Date(date)).getMonth() + 1);
                    setAnio((new Date(date)).getFullYear());
                    setHeightCalendar(weekHeight((new Date(date)).getFullYear(), (new Date(date)).getMonth() + 1));
                }}
                onSelectEvent={(event, target) => {
                    setEventoActual2(eventos.filter(e => e.id === event.id)[0]);
                    setOpenPopoverEvento(true);
                    setAnchorEl2(target.currentTarget);
                }}
                dayPropGetter={(date) => { if (date.getMonth() + 1 !== mes || ((date < startDate || date > endDate) && (date.toISOString().split('T')[0] !== startDate.toISOString().split('T')[0] && date.toISOString().split('T')[0] !== endDate.toISOString().split('T')[0]))) return { style: { background: '#E6E6E6' } } }}
                eventPropGetter={(e) => {
                    return {
                        style: { backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2 }
                    }
                }}
            />
            {eventosPopup !== null && fechaPopup !== null ?
                <Popover
                    open={openPopoverPopup}
                    onClose={() => { setOpenPopoverPopup(false); setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150); }}
                    anchorEl={anchorEl3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ width: 250, margin: theme.spacing(1) }}>
                        <span>{format(fechaPopup, 'EEEE dd, MMM', { locale: EsLocale })}</span>
                        <Divider style={{ marginBottom: theme.spacing(0.5) }} />
                        {eventosPopup.map(e =>
                            <div style={{ marginBottom: 1 }}>
                                <div
                                    style={{ borderRadius: 5, backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2, padding: '2px 5px', cursor: 'pointer' }}
                                    onClick={(evento) => {
                                        setEventoActual2(eventos.filter(ev => ev.id === e.id)[0]);
                                        setOpenPopoverEvento(true);
                                        setAnchorEl2(evento.currentTarget);
                                    }}
                                >
                                    {e.title}
                                </div>
                            </div>
                        )}
                    </div>
                </Popover>
                :
                null
            }
            {
                eventoActual2 !== null ?
                    <Popover
                        open={openPopoverEvento && eventoActual2 !== null}
                        onClose={() => { closePopoverEvento() }}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ width: encuestador.length === 1 && encuestador[0] !== 'TODOS LOS ENCUESTADORES' ? 200 : 220, maxHeight: 300, overflowY: 'auto', margin: theme.spacing(1) }}>
                            {encuestador.length === 1 && encuestador[0] !== 'TODOS LOS ENCUESTADORES' ?
                                eventoActual2.actividades[encuestador].map(a =>
                                    <div style={{ marginBottom: 2 }}>
                                        <div
                                            style={{ borderRadius: 5, background: getColor(a)[0], color: getColor(a)[1], padding: '2px 5px' }}
                                        >
                                            {a.title}
                                        </div>
                                    </div>
                                )
                                :
                                eventoActual2.encuestadores.map(e => (
                                    <React.Fragment>
                                        <div style={{ marginBottom: 2 }}>
                                            <div onClick={() => { encuestadorActual === e ? setEncuestadorActual(null) : setEncuestadorActual(e) }} style={{ height: 26.4, background: encuestadorActual === e ? eventoActual2.color + 'b3' : null, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', borderRadius: 5, background: encuestadorActual !== null ? encuestadorActual === e ? eventoActual2.color : eventoActual2.color + '66' : eventoActual2.color, color: eventoActual2.color2, cursor: 'pointer', padding: '2px 5px' }}>
                                                    {e}
                                                </div>
                                            </div>
                                            <Collapse in={encuestadorActual === e}>
                                                <div style={{ background: eventoActual2.color + 'b3', paddingTop: 3, paddingBottom: 3, marginBottom: 1, borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }}>
                                                    <div style={{ marginLeft: theme.spacing(1.5), marginRight: theme.spacing(1) }}>
                                                        {eventoActual2.actividades[e].map(a => (
                                                            <div style={{ height: 26.4, width: '100%', marginBottom: 1 }}>
                                                                <div
                                                                    style={{ borderRadius: 5, background: getColor(a)[0], color: getColor(a)[1], padding: '2px 5px' }}
                                                                >
                                                                    {a.title}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </React.Fragment>
                                ))}
                        </div>
                    </Popover>
                    :
                    null
            }
        </React.Fragment >
    );
}

export default Calendario;
import React, { useState, useEffect } from 'react';
import Logo from '../logo.png';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Container,
    Backdrop,
    CircularProgress,
    Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom";

import useBreakpoints from './styles/useBreakpoints';
import Sesion from './components/Sesion';
import Reporte from './components/Reporte/Reporte';
import TemporaryDrawer from './components/TemporaryDrawer';
import Resumen from './components/Resumen/Resumen';
import Copyright from './components/Copyright';
import PeticionPermisos from './components/Permisos/PeticionPermisos';

const API_DEFAULT = 'https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app';
// const API_DEFAULT = 'http://127.0.0.1:8000';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 500
    },
    a: {
        height: 37.39,
        [theme.breakpoints.up('sm')]: {
            height: 45.89
        },
    },
    logo: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135
        },
        marginRight: theme.spacing(4)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


function Productividad(props) {
    const classes = useStyles();
    const theme = props.theme;
    const [openDrawer, setOpenDrawer] = useState(false);
    const point = useBreakpoints();
    const  paths = ['/inicio-sesion', '/reporte-productividad', '/vista-resumen', '/dar-permisos'];
    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);

    const [auth, setAuth] = useState(localStorage.getItem('auth'));
    const [accessToken, setAccessToken] = useState(localStorage.getItem('access'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh'));

    const [username, setUsername] = useState(localStorage.getItem('username'));
    const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [snack, setSnack] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [reporte, setReporte] = useState( ['/reporte-productividad', '/vista-resumen', '/crear-usuario'].indexOf(pathname));
    const [validRole, setValidRole] = useState(false);

    const validateSession = (username, password) => {
        let errorSesion = false;
        if (username === '') {
            errorSesion = true;
            sessionErrors[0] = true;
        }
        if (password === '') {
            errorSesion = true;
            sessionErrors[1] = true;
        }
        if (errorSesion) {
            setSessionErrors([...sessionErrors]);
            setMessage('Existen campos sin diligenciar o con alg�n error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            logIn(username, password);
        }
    }

    const logIn = async (username, password) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/usuarios/auth/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        })
        res
            .json()
            .then(d => {
                if (d['access']) {
                    getRole(d['access'], d['refresh']);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Los datos de usuario y contrase�a son incorrectos.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
    }

    const getRole = async (access = accessToken, refresh = refreshToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/usuarios/dar_rol/`, {
            headers: { 'Authorization': `Bearer ${access}` }
        })

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    getRole(newAccess, refresh);
                }
            }
            else if (res['roles'].includes('Administrador') || res['roles'].includes('Reportador productividad telefonico')) {
                localStorage.setItem('username', res['username']);
                localStorage.setItem('access', access);
                localStorage.setItem('refresh', refresh);
                localStorage.setItem('auth', true);
                setUsername(res['username']);
                setAccessToken(access);
                setRefreshToken(refresh);
                setAuth(true);
                setValidRole(true);
                setShowBackdrop(false);
            }
            else {
                signOff();
                setShowBackdrop(false);
                setMessage('El usuario no est� habilitado para usar esta aplicaci�n.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        })
    }

    const getAccessTokenWithRefresh = async () => {
        let access = undefined;
        const res = await fetch(`${API_DEFAULT}/usuarios/refresh_token/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'refresh': refreshToken
            })
        });

        if (!res.ok) {
            signOff();
            setShowBackdrop(false);
            setMessage('La sesi�n expir�, por favor ingrese nuevamente.');
            setSeverity('info');
            setTimeout(() => { setSnack(true) }, 0);
        }

        await res.json().then(res => {
            if (res['code'] === 'token_not_valid') {
                signOff();
                setShowBackdrop(false);
                setMessage('La sesi�n expir�, por favor ingrese nuevamente.');
                setSeverity('info');
                setTimeout(() => { setSnack(true) }, 0);
            }
            else {
                access = res['access'];
            }
        });

        return access;
    }

    const signOff = () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('username');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        setAuth(false);
        setUsername('');
        setAccessToken('');
        setRefreshToken('');
        setValidRole(false);
    }

    useEffect(() => {
        setPathname(location.pathname);
        setReporte(location.pathname !== '/vista-resumen');
    }, [location]);

    useEffect(() => {
        if (auth) {
            getRole(accessToken, refreshToken);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <AppBar position='static'>
                <Toolbar>
                    {auth ?
                        <IconButton
                            style={{ marginRight: theme.spacing(2) }}
                            color="inherit"
                            edge="start"
                            onClick={() => setOpenDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        :
                        null
                    }
                    <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
                        <img src={Logo} alt='logo' className={classes.logo} />
                    </a>
                    <Typography style={{ marginRight: theme.spacing(2) }} variant='h5' className={classes.title}>
                        {point !== 'xs' ? 'Control de productividad - Telef�nico' : 'Productividad - Telef�nico'}
                    </Typography>
                    {auth ?
                        <React.Fragment>
                            <Typography style={{ display: point === 'sm' || point === 'xs' ? 'none' : null, fontWeight: 500, marginRight: theme.spacing(2) }} variant='body1'>{username}</Typography>
                            <IconButton edge='start' color='inherit' onClick={() => { localStorage.removeItem('token'); localStorage.removeItem('auth'); localStorage.removeItem('username'); setAccessToken(); setAuth(); setUsername(); setReporte(true) }}>
                                <ExitToAppIcon />
                            </IconButton>
                        </React.Fragment>
                        :
                        null
                    }
                </Toolbar>
            </AppBar>
            <div style={{ marginTop: theme.spacing(2) }}>
                <Redirect to={{ pathname: auth ? paths.slice(1).includes(pathname) ? pathname : '/reporte-productividad' : '/inicio-sesion', state: { from: props.location } }} />
                {auth ?
                    <TemporaryDrawer
                        theme={theme}
                        classes={classes}
                        open={[openDrawer, setOpenDrawer]}
                        reporte={[reporte, setReporte]}
                    />
                    :
                    null
                }
                <Switch>
                    <Route exact path={paths[0]}>
                        {!auth ?
                            <Container component='main' maxWidth='xs'>
                                <Sesion
                                    theme={theme}
                                    errors={[sessionErrors, setSessionErrors]}
                                    validateSession={validateSession}
                                />
                            </Container>
                            :
                            null
                        }
                    </Route>
                    <Route exact path={paths[1]}>
                        {auth ?
                            <Container component='main' maxWidth='xl'>
                                <Reporte
                                    theme={theme}
                                    API_DEFAULT={API_DEFAULT}
                                    validRole={validRole}
                                    accessToken={[accessToken, setAccessToken]}
                                    username={username}
                                    setShowBackdrop={setShowBackdrop}
                                    setSnack={setSnack}
                                    setMessage={setMessage}
                                    setSeverity={setSeverity}
                                    point={point}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                />
                            </Container>
                            :
                            <Redirect to={'/inicio-sesion'} />
                        }
                    </Route>
                    <Route exact path={paths[2]}>
                        {auth ?
                            <Container component='main' maxWidth='xl'>
                                <Resumen
                                    theme={theme}
                                    API_DEFAULT={API_DEFAULT}
                                    validRole={validRole}
                                    accessToken={[accessToken, setAccessToken]}
                                    username={username}
                                    setShowBackdrop={setShowBackdrop}
                                    setSnack={setSnack}
                                    setMessage={setMessage}
                                    point={point}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                />
                            </Container>
                            :
                            <Redirect to={'/inicio-sesion'} />

                        }
                    </Route>
                    <Route exact path={paths[3]}>
                        {auth ?
                            <Container component='main' maxWidth='xl'>
                                <PeticionPermisos
                                    theme={theme}
                                    API_DEFAULT={API_DEFAULT}
                                    validRole={validRole}
                                    accessToken={[accessToken, setAccessToken]}
                                    username={username}
                                    setShowBackdrop={setShowBackdrop}
                                    setSnack={setSnack}
                                    setMessage={setMessage}
                                    setSeverity={setSeverity}
                                    point={point}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                />
                            </Container>
                            :
                            <Redirect to={'/inicio-sesion'} />
                        }
                    </Route>
                </Switch>
            </div>
            <Copyright />
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <Snackbar
                open={snack}
                autoHideDuration={6000}
                onClose={() => setSnack(false)}
            >
                <Alert onClose={() => setSnack(false)} severity={severity} variant='filled'>
                    {message}
                </Alert>
            </Snackbar>
        </div >
    );
}

export default Productividad;
import React, { useState, useEffect, useRef } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    CardHeader,
    Divider,
    Chip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import DateRangePicker from './components/DateRangePicker';
import Calendario from './components/Calendario';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const randDarkColor = () => {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

function PeticionPermisos(props) {
    const { getAccessTokenWithRefresh, validRole } = props;
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const username = props.username;
    const setShowBackdrop = props.setShowBackdrop;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const point = props.point;
    const [openEnc, setOpenEnc] = useState(false);
    const [openListEnc, setOpenListEnc] = useState(false);

    const [encuestador, setEncuestador] = useState([]);
    const [cedula, setCedula] = useState([]);
    const [estudio, setEstudio] = useState([]);

    const [generar, setGenerar] = useState(false);

    const [encuestadores, setEncuestadores] = useState([]);
    const [eventos, setEventos] = useState([]);

    const refEnc = useRef(null);
    const refEst = useRef(null);

    const [startDate, startDateChange] = useState(new Date());
    const [endDate, endDateChange] = useState(new Date());

    const [selectedDateTime, setSelectedDateTime] = useState(new Date());

    const [searching, setSearching] = useState(false);
    const [open, setOpen] = useState(false);
    const [justification, setJustification] = useState('');




    let inputValue = React.useState('');
    const loading = openEnc && encuestadores.length === 0;
    let controller = new AbortController();
    let timeout;

    const fetchEncuestadores = async (access = accessToken) => {

        if (inputValue.length > 0) {

            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal_productividad/apk=2/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }

                    }else{
                        setEncuestadores(res.sort((first, second) => (first['name'] - second['name'])));
                        setShowBackdrop(false);
                        setSearching(false)
                    }
                });
            }
            else{
                setSearching(false);
                setEncuestadores([]);
            }

        }else{
            setSearching(false);
            setEncuestadores([]);
        }
    }
    







    const generarResumen = async (access = accessToken) => {
        setShowBackdrop(true);
        
        const url = `${API_DEFAULT}/productividad/custom_open/`; // URL del endpoint POST en el backend
        
        const data = {
            authorized_user: cedula,
            register: username,
            start_edition_date: startDate.toISOString(), // Aseg�rate de que startDate sea una instancia de Date
            end_edition_date: endDate.toISOString(), // Aseg�rate de que endDate sea una instancia de Date
            close_date: selectedDateTime.toISOString(), // Aseg�rate de que selectedDateTime sea una instancia de Date
            observation: justification,
            source: 2,
        };
        
        const requestOptions = {
            method: 'POST',
            headers: {
            'Authorization': 'Bearer ' + access,
            'Content-Type': 'application/json', // Especificamos que estamos enviando datos en formato JSON
            },
            body: JSON.stringify(data), // Convertimos el objeto a JSON y lo enviamos como cuerpo de la solicitud
        };
        console.log(requestOptions.body);
        try {
            const res = await fetch(url, requestOptions);
        
            if (res.ok) {
            // Manejar la respuesta del servidor si es necesario
            console.log('Solicitud POST exitosa');
            } else if (res.status === 401 && res.headers.get('www-authenticate') === 'Bearer error="token_not_valid"') {
            // Si el token no es v�lido, renovar el token y volver a intentar la solicitud
            let newAccess = await getAccessTokenWithRefresh();
            if (newAccess) {
                setAccessToken(newAccess);
                localStorage.setItem('access', newAccess);
        
                generarResumen(newAccess);
            }
            } else {
            console.error('Error en la solicitud POST');
            }
        } catch (error) {
            console.error('Error en la solicitud POST:', error);
        }
        
        setShowBackdrop(false);
        };






    const generarResumenOld = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/productividad/resumen/tipo_campo=${estudio.tipo_campo !== undefined?estudio.tipo_campo:0}&cedula=${cedula}&centro_costos=${estudio}&fecha_ini=${startDate.toISOString().split('T')[0]}&fecha_fin=${endDate.toISOString().split('T')[0]}&descargar=${false}/`, {
            headers: { 'Authorization': 'Bearer ' + access },
            
        })

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    generarResumen(newAccess);
                }
            }
            else {
                var aux = [];
                var aux2 = [];
                var id = 1;
                const group = res.reduce((r, { estudio, start, end, cedula, nombre, title, tipo_actividad }) => {
                    cedula = cedula + ': ' + nombre
                    let e = {
                        authorized_user: cedula,
                        register: username,
                        start_edition_date: startDate.toISOString().split('T')[0],
                        end_edition_date: endDate.toISOString().split('T')[0],
                        close_date: selectedDateTime,
                        observation: justification,

                        id: id,
                        title: estudio,
                        end: end,
                        start: start,
                        actividades: [],
                        encuestadores: []
                    }
                    if (!r.map(r => [r.title, r.start]).some(v => v[0] === estudio && v[1] === start)) {
                        r[r.length] = e;
                        aux[[estudio, start]] = r.length - 1;
                        id = id + 1
                    }
                    if (r[aux[[estudio, start]]].actividades[cedula] === undefined) {
                        r[aux[[estudio, start]]].encuestadores.push(cedula)
                        r[aux[[estudio, start]]].actividades[cedula] = []
                    }
                    if (aux2[estudio] === undefined) {
                        aux2[estudio] = randDarkColor();
                    }
                    r[aux[[estudio, start]]].actividades[cedula].push({
                        title: title,
                        actividad: tipo_actividad === undefined ? 'E' : tipo_actividad
                    });
                    r[aux[[estudio, start]]].color = aux2[estudio];
                    r[aux[[estudio, start]]].color2 = 'white';
                    return r;
                }, []);
                setEventos(group);
                setShowBackdrop(false);
                setGenerar(true);
            }
        });
    }    

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    useEffect(() => {
        if (validRole) {
            fetchEncuestadores();
        }
    }, [validRole]) // eslint-disable-line react-hooks/exhaustive-deps

    const limpiar = () => {
        startDateChange(new Date());
        endDateChange(new Date());
        setSelectedDateTime(null);
        setGenerar(false);
        setEncuestador([]);
        setCedula([]);
        setEventos([]);
    }
    useEffect(() => {
        if (!open) {
            setEncuestadores([]);
        }
    }, [open]);
console.log('###########')
console.log(encuestadores);
    return (
        <Card >
            <CardHeader
                title={
                    <Typography variant='h6'>Autorizaciones para cargue de productividad</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Seleccione las personas:</Typography>
                        <Autocomplete
                            disabled={generar}
                            multiple
                            filterSelectedOptions={true}
                            open={openListEnc}
                            onFocus={() => !openEnc ? setOpenEnc(true) : null}
                            onOpen={() => { if (!openEnc) setOpenEnc(true); if (!openListEnc) setOpenListEnc(true) }}
                            onClose={() => { if (encuestador.length < 1) setOpenEnc(false); setOpenListEnc(false) }}
                            onBlur={() => setOpenEnc(false)}
                            limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                            size='small'
                            value={encuestador}
                            loading={loading}
                            onChange={(event, value) => {
                                if (value.length > 0) {
                                    let newUsername = []
                                    for (var i = 0; i < value.length; i++) {
                                        newUsername.push(value[i].split(': ')[0]); // Splitting by ': ' and taking the username part
                                    }
                                    setCedula(newUsername); // It might be better to rename this state variable to something like setSelectedUsernames
                                }
                                else {
                                    setCedula([]); // Consider renaming this as well
                                }
                                setEncuestador(value);
                            }}
                            options={
                                encuestador.length === 0 ?
                                    encuestadores.map(option => option.username + ': ' + option.name)
                                    :
                                    encuestadores.slice(1).map(option => option.username + ': ' + option.name)
                            }
                            noOptionsText='No hay coincidencias'
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{ overflow: 'hidden' }}
                                        variant="outlined"
                                        label={option}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderOption={(option) => {
                                if (option === 'TODOS LOS SUPERVISORES')
                                    return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                else
                                    return (<span>{option}</span>);
                            }}
                            renderInput={params => {
                                const { inputProps, InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;

                                return (
                                    <TextField
                                        {...restParams}
                                        size="small"
                                        InputProps={{
                                            onKeyDown: (e) => {
                                                if (e.key === 'ArrowLeft') {
                                                    e.stopPropagation()
                                                } else {
                                                    e.persist();
                                                    clearTimeout(timeout);

                                                    timeout = setTimeout(() => {
                                                        inputValue = e.target.value;
                                                        handleChange();
                                                        clearTimeout(timeout);
                                                    }, 700);
                                                }
                                            },
                                            ...restInputProps,
                                            startAdornment: (
                                                <div ref={refEnc} onClick={() => { if (!openListEnc) setOpenListEnc(true); if (!openEnc) setOpenEnc(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openEnc ? 'auto' : null, display: openEnc ? null : 'flex', flexDirection: openEnc ? null : 'row' }}>
                                                    {startAdornment}
                                                </div>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),

                                        }}
                                        inputProps={{ ...inputProps, style: { position: openEnc || encuestador.length === 0 ? null : 'absolute', width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)', zIndex: openEnc || encuestador.length === 0 ? null : -1 } }}
                                        variant="outlined"
                                        autoFocus={true}
                                        fullWidth
                                        helperText={encuestador.length > 1 ? 'Nota: Recuerde que cuando se selecciona varios encuestadores, las actividades que aparecen en el calendario son las que tienen los seleccionados en com�n.' : null}
                                    />

                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                  <Grid item xs>
                        <Typography variant='body1'>Seleccione el rango de fechas en las que puede editar:</Typography>
                        <DateRangePicker
                            disabled={generar}
                            theme={theme}
                            start={[startDate, startDateChange]}
                            end={[endDate, endDateChange]}
                        />
                    </Grid>
              
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >

                    <Grid item xs>
                        <Typography variant='body1'>Seleccione fecha y hora que remover� el permiso de editar:</Typography>
                        <DatePicker
                            selected={selectedDateTime}
                            onChange={(date) => setSelectedDateTime(date)}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd h:mm aa"
                        />
                        </Grid>
                    

                            <Grid item xs>
                                <TextField
                                label="Justificaci�n"
                                variant="outlined"
                                placeholder="Coloca la justificaci�n para la apertura del aplicativo"
                                onChange={(e) => setJustification(e.target.value)}
                                required={true}
                                type="text"
                                />
                            </Grid>

                    <Grid item xs={point === 'sm' || point === 'xs' ? 12 : null}>
                        <Grid container
                            direction='row'
                            justify={point === 'sm' || point === 'xs' ? 'flex-end' : null}
                        >
                            <Button
                                size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                disabled={encuestador.length === 0 || startDate.length === 0  || selectedDateTime === null || generar}
                                style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                variant='contained' color='primary'
                                onClick={() => { generarResumen() }}
                            >
                                Enviar
                            </Button>
                            <ThemeProvider theme={themeDownloadButton}>
    
                            </ThemeProvider>
                            <Button
                                size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                disabled={encuestador.length === 0 && estudio.length === 0 && startDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] && endDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]}
                                style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3) }}
                                variant='contained' color='secondary'
                                onClick={() => { limpiar() }}
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    generar ?
                        <div style={{ marginTop: theme.spacing(3) }} className='Calendario'>
                            <Calendario
                                theme={theme}
                                username={username}
                                eventos={eventos}
                                encuestador={encuestador}
                                dates={[startDate, endDate]}
                                setSnack={setSnack}
                                setMessage={setMessage}
                            />
                        </div>
                        :
                        null
                }
            </CardContent>
        </Card>

    );
}

export default PeticionPermisos;
